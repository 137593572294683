<template>
  <Swiper
    :modules="modules"
    :slides-per-view="1"
    :spaceBetween="100"
    :speed="800"
    :parallax="true"
    class="relative m-0 mx-auto h-auto w-full p-0 !pt-22 text-left lg:!pt-10"
  >
    <TabsNav :tabs="data" />
    <SwiperSlide v-for="(slide, idx) in data" :key="idx">
      <div class="grid grid-cols-6 gap-y-10 gap-x-4 pb-10 lg:grid-cols-12 lg:gap-y-0 lg:gap-x-8">
        <div class="relative z-1 col-span-6 lg:order-2 xl:col-span-7">
          <Image
            v-if="slide.media"
            class="aspect-4/3 object-cover"
            loading="lazy"
            sizes="(min-width: 1280px) 681px, (min-width: 1040px) calc(50vw - 32px), (min-width: 420px) calc(100vw - 32px)"
            :src="slide.media.responsiveImage ? slide.media.responsiveImage?.src : slide.media.url"
            :srcset="
              slide.media.responsiveImage ? slide.media.responsiveImage?.webpSrcSet : undefined
            "
            :width="
              slide.media.responsiveImage ? slide.media.responsiveImage.width : slide.media.width
            "
            :height="
              slide.media.responsiveImage ? slide.media.responsiveImage.height : slide.media.height
            "
            :alt="slide.media.alt ? slide.media.alt : ''"
          />
          <component
            v-for="block in slide.blocks"
            :is="resolveBlockComponent(block.__typename as Blocks)"
            :data="block"
            :key="block.__typename"
          />
        </div>
        <div class="col-span-6 lg:order-1 xl:col-span-5">
          <div class="flex flex-col justify-center">
            <div class="lg:pt-30">
              <Heading :content="slide.title" importance="3" />
              <div v-html="slide.html" class="prose lg:text-lg"></div>
              <CallToAction
                v-if="Array.isArray(slide.buttons) && slide.buttons.length"
                :data="slide.buttons"
                class="mt-6"
              />
            </div>
          </div>
        </div>
      </div>
    </SwiperSlide>
  </Swiper>
</template>

<script setup lang="ts">
import { Parallax, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Blocks } from '~/types/components'

import 'swiper/css'

const { resolveBlockComponent } = useResolveComponent()

defineProps({
  data: {
    type: Array,
    default: () => []
  }
})

const modules = [Parallax, A11y]
</script>

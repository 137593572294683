<template>
  <div class="scrollbar absolute top-0 left-0 h-[55px] w-full overflow-scroll lg:h-[78px]">
    <div class="flex w-full min-w-[800px] border-b-2 border-gray1">
      <span class="flex-1 px-3 text-center" v-for="(item, idx) in tabs" :key="idx">
        <button class="relative border-none" :class="[$style.filter]" @click="swiper.slideTo(idx)">
          {{ item.tabTitle }}
          <span
            class="absolute bottom-0 left-0 h-[3px] transform-gpu bg-primary transition-all duration-300 ease-back-out"
            :class="swiper.realIndex === idx ? 'w-full' : ' w-0'"
          ></span>
        </button>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSwiper } from 'swiper/vue'

const swiper = useSwiper()

defineProps({
  tabs: { type: Array, default: () => [] }
})
</script>

<style lang="postcss" module>
.filter {
  @apply -mb-0.5 inline-flex whitespace-nowrap border-b-2 pb-6 text-base-semibold uppercase hover:text-primary lg:pb-8 lg:text-xl-semibold;
}
</style>
